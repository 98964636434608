import React, { useContext } from 'react'
import { Switch } from 'react-router-dom'
import PrivatedRoute from 'src/presentation/components/PrivatedRoute'
import { MainLayout } from 'src/presentation/layouts'
import {
  ACCOUNTS,
  ACCOUNT_DETAIL,
  ACCOUNT_FILE_VALIDATION,
  STRATEGIES,
  STRATEGY,
  RECALL_ACCOUNT_FILE_VALIDATION,
  TRANSACTION_FILE_VALIDATION,
  SEARCH,
  BUSINESS_SETTINGS,
  UPDATE_ACCOUNT_FILE_VALIDATION,
  RESTING_AREA,
  CHARGE_OFF_RESTING_ACCOUNT_FILE_VALIDATION,
  BULK_POST_PLACEMENT,
  COMPLAINT_CREATE,
  COMPLAINT,
  COMPLAINT_INFO_NEW,
  COMPLAINT_INFO,
  PARAMETER_MANAGER,
  BULK_POST_PLACEMENT_RESULT,
  POST_PLACEMENT,
  POST_PLACEMENT_MANAGER,
  CHARGE_OFF_STRATEGY_ACCOUNT_FILE_VALIDATION,
  BULK_POST_PLACEMENT_RESPOND,
} from 'src/presentation/routes'
import { Permissions } from 'src/domain/models/permissions'
import makeAccounts from 'src/main/factories/pages/Accounts'
import postPlacementDetail from 'src/main/factories/pages/PostPlacement/PostPlacementDetails'
import postPlacementManager from 'src/main/factories/pages/PostPlacement'
import bulkPostPlacement from 'src/main/factories/pages/BulkPostPlacement'
import bulkPostPlacementResult from 'src/main/factories/pages/BulkPostPlacement/Result'
import bulkPostPlacementResponse from 'src/main/factories/pages/BulkPostPlacement/Respond'
import makeAccountDetail from 'src/main/factories/pages/Accounts/AccountsDetail'
import makeNotFound from 'src/main/factories/pages/NotFound'
import makeStrategyList from 'src/main/factories/pages/Strategy/StrategiesManager/List'
import { AbilityContext } from 'src/context/Can'
import { AuthContext } from 'src/context/AuthenticationContext'

import { buildAbilityFor } from 'src/configs/ability'
import { PermissionCodeAccess } from 'src/utils/constants'
import makeAccessDenied from 'src/main/factories/pages/AccessDenied'
import makeAccountFileValidation from 'src/main/factories/pages/Accounts/FileValidation/makeAccountFileValidation'
import makeRecallFileValidation from 'src/main/factories/pages/Accounts/FileValidation/makeRecallFileValidation'
import makeTransactionFileValidation from 'src/main/factories/pages/Accounts/FileValidation/makeTransactionFileValidation'
import makeSearch from 'src/main/factories/pages/Search'
import makeBusinessSettings from 'src/main/factories/pages/Business/BusinessSettings'
import makeUpdateAccountFileValidation from 'src/main/factories/pages/Accounts/FileValidation/makeUpdateAccountFileValidation'
import makeRestingArea from 'src/main/factories/pages/RestingArea'
import makeChargeOffAccountFileValidation from 'src/main/factories/pages/RestingArea/FileValidation/makeChargeOffAccountFileValidation'
import { IAuthContext } from 'src/context/Interfaces'
import makeComplaintSearchAccounts from 'src/main/factories/pages/Complaint/SearchAccounts/makeSearchAccounts'
import makeComplaintList from 'src/main/factories/pages/Complaint/List'
import makeComplaintDetail from 'src/main/factories/pages/Complaint/Detail'
import makeParameterManager from 'src/main/factories/pages/Strategy/ParameterManager'
import makeStrategyForm from 'src/main/factories/pages/Strategy/StrategiesManager/Form'
import makeChargeOffStrategyAccountFileValidation from 'src/main/factories/pages/Accounts/FileValidation/makeChargeOffStrategyAccountFileValidation'

interface AppProps {
  managePermission: Permissions[]
}
interface AbilityProviderProps {
  children: React.ReactNode
  permissionValues: Permissions[]
}

const AbilityProvider = ({
  children,
  permissionValues,
}: AbilityProviderProps) => {
  const { userPermissions } = useContext(AuthContext)
  const ability = buildAbilityFor(userPermissions.type, permissionValues)
  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  )
}
interface PermissionReduce {
  [field: string]: boolean
}
const permissionReduce = (
  permissionValues: Permissions[] = []
): PermissionReduce =>
  permissionValues.reduce(
    (acc: any, item: Permissions) => ({ ...acc, [item.code]: item.value }),
    {}
  )

const App = ({ managePermission }: AppProps) => {
  const { isVendor } = useContext<IAuthContext>(AuthContext)
  return (
    <AbilityProvider permissionValues={managePermission}>
      <MainLayout>
        <Switch>
          <PrivatedRoute
            exact
            path={`${ACCOUNT_DETAIL}/:ECAID/:backUrl?`}
            component={makeAccountDetail}
            permissionValues={managePermission}
          />
          <PrivatedRoute
            exact
            path={`${STRATEGY}/:id?`}
            permissionValues={managePermission}
            component={
              !isVendor &&
              permissionReduce(managePermission)[
                PermissionCodeAccess.Recover_CreateStrategies
              ]
                ? makeStrategyForm
                : makeAccessDenied
            }
          />
          <PrivatedRoute
            permissionValues={managePermission}
            exact
            path={`${STRATEGIES}`}
            component={
              !isVendor &&
              permissionReduce(managePermission)[
                PermissionCodeAccess.Recover_CreateStrategies
              ]
                ? makeStrategyList
                : makeAccessDenied
            }
          />
          <PrivatedRoute
            permissionValues={managePermission}
            component={makeAccounts}
            exact
            path={ACCOUNTS}
          />
          <PrivatedRoute
            permissionValues={managePermission}
            component={postPlacementManager}
            exact
            path={POST_PLACEMENT_MANAGER}
          />
          <PrivatedRoute
            permissionValues={managePermission}
            component={postPlacementDetail}
            exact
            path={`${POST_PLACEMENT}/:requestId`}
          />
          <PrivatedRoute
            permissionValues={managePermission}
            component={bulkPostPlacement}
            exact
            path={BULK_POST_PLACEMENT}
          />
          <PrivatedRoute
            permissionValues={managePermission}
            component={bulkPostPlacementResult}
            exact
            path={BULK_POST_PLACEMENT_RESULT}
          />
          <PrivatedRoute
            permissionValues={managePermission}
            component={bulkPostPlacementResponse}
            exact
            path={BULK_POST_PLACEMENT_RESPOND}
          />
          <PrivatedRoute
            permissionValues={managePermission}
            component={makeRestingArea}
            exact
            path={RESTING_AREA}
          />
          <PrivatedRoute
            permissionValues={managePermission}
            component={makeAccountFileValidation}
            exact
            path={ACCOUNT_FILE_VALIDATION}
          />
          <PrivatedRoute
            permissionValues={managePermission}
            component={makeRecallFileValidation}
            exact
            path={RECALL_ACCOUNT_FILE_VALIDATION}
          />
          <PrivatedRoute
            permissionValues={managePermission}
            component={makeTransactionFileValidation}
            exact
            path={TRANSACTION_FILE_VALIDATION}
          />
          <PrivatedRoute
            permissionValues={managePermission}
            component={makeSearch}
            exact
            path={`${SEARCH}/:pageNumber/:pageSize/:searchInput/:searchType`}
          />
          <PrivatedRoute
            permissionValues={managePermission}
            component={makeBusinessSettings}
            exact
            path={BUSINESS_SETTINGS}
          />
          <PrivatedRoute
            permissionValues={managePermission}
            component={makeUpdateAccountFileValidation}
            exact
            path={UPDATE_ACCOUNT_FILE_VALIDATION}
          />
          <PrivatedRoute
            permissionValues={managePermission}
            component={makeChargeOffAccountFileValidation}
            exact
            path={CHARGE_OFF_RESTING_ACCOUNT_FILE_VALIDATION}
          />
          <PrivatedRoute
            permissionValues={managePermission}
            component={makeChargeOffStrategyAccountFileValidation}
            exact
            path={CHARGE_OFF_STRATEGY_ACCOUNT_FILE_VALIDATION}
          />
          <PrivatedRoute
            permissionValues={managePermission}
            exact
            path={COMPLAINT}
            component={
              permissionReduce(managePermission)[
                PermissionCodeAccess.Recover_Complaint
              ]
                ? makeComplaintList
                : makeAccessDenied
            }
          />
          <PrivatedRoute
            permissionValues={managePermission}
            exact
            path={COMPLAINT_CREATE}
            component={
              permissionReduce(managePermission)[
                PermissionCodeAccess.Recover_Complaint_CreateComplaint
              ]
                ? makeComplaintSearchAccounts
                : makeAccessDenied
            }
          />
          <PrivatedRoute
            permissionValues={managePermission}
            exact
            path={`${COMPLAINT_INFO_NEW}/:ecaid`}
            component={
              permissionReduce(managePermission)[
                PermissionCodeAccess.Recover_Complaint_CreateComplaint
              ]
                ? makeComplaintDetail
                : makeAccessDenied
            }
          />
          <PrivatedRoute
            permissionValues={managePermission}
            exact
            path={`${COMPLAINT_INFO}/:complaintId`}
            component={
              permissionReduce(managePermission)[
                PermissionCodeAccess.Recover_Complaint
              ]
                ? makeComplaintDetail
                : makeAccessDenied
            }
          />
          <PrivatedRoute
            permissionValues={managePermission}
            component={makeParameterManager}
            exact
            path={PARAMETER_MANAGER}
          />
          <PrivatedRoute
            permissionValues={managePermission}
            component={makeNotFound}
            path="*"
          />
        </Switch>
      </MainLayout>
    </AbilityProvider>
  )
}

export default App
