/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability'
import { Permissions } from 'src/domain/models/permissions'

type Actions =
  | '004'
  | '004.000'
  | '004.000.000'
  | '004.000.001'
  | '004.000.002'
  | '004.000.003'
  | '004.000.004'
  | '004.000.005'
  | '004.000.006'
  | '004.000.007'
  | '004.000.008'
  | '004.000.009'
  | '004.000.010'
  | '004.000.011'
  | '004.000.012'
  | '004.000.013'
  | '004.000.014'
  | '004.001'
  | '004.001.000'
  | '004.001.001'
  | '004.002'
  | '004.002.000'
  | '004.002.001'
  | '000.001'

type Subjects = 'any'

export type AppAbility = Ability<[Actions, Subjects]>

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppAbility = Ability as AbilityClass<AppAbility>

export default function defineRulesFor(
  role: string,
  permissionValues: Permissions[]
) {
  const { can, cannot, rules } = new AbilityBuilder<AppAbility>(AppAbility)

  permissionValues.forEach((v) => {
    if (v.value) {
      can(v.code as Actions, 'any')
    } else {
      cannot(v.code as Actions, 'any')
    }
  })

  return rules
}

export function buildAbilityFor(
  role: string,
  permissionValues: Permissions[]
): AppAbility {
  return new AppAbility(defineRulesFor(role, permissionValues))
}
