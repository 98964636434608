import { Box, Content, Header } from 'everchain-uilibrary'
import React, { useCallback, useContext, useState } from 'react'
import { IBusinessGetOperations } from 'src/domain/features/get/business/business'
import { IPostPlacementGetOperations } from 'src/domain/features/get/postPlacement/postPlacement'
import { IPostPlacementPostOperations } from 'src/domain/features/post/postPlacement/postPlacement'
import { BULK_POST_PLACEMENT_RESPOND } from 'src/presentation/routes'
import { getStandardUri } from 'src/utils/common'

interface BulkPostPlacementRespondParams {
  businessOperations?: IBusinessGetOperations
  postPlacementOperations?: IPostPlacementGetOperations
  postPlacementPostOperations?: IPostPlacementPostOperations
}

const BulkPostPlacementRespond: React.FC<BulkPostPlacementRespondParams> = ({
  businessOperations,
  postPlacementOperations,
  postPlacementPostOperations,
}) => {
  return (
    <Box>
      <Content>
        <Header
          title="Bulk Post Placement Respond"
          customBackUrl={getStandardUri(BULK_POST_PLACEMENT_RESPOND)}
          style={{ padding: 20 }}
        />
      </Content>
    </Box>
  )
}

export default BulkPostPlacementRespond
