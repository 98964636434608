import React, { useContext, useState } from 'react'
import {
  Box,
  Content,
  DataTable,
  DataTableState,
  Form,
  FormDropdown,
  FormSubmitButton,
  Group,
  Header,
  useForm,
} from 'everchain-uilibrary'
import Creditors from '../../Accounts/components/Creditors'
import { IBusinessGetOperations } from 'src/domain/features/get/business/business'
import Vendors from '../../Accounts/components/Vendors'
import { useCustomQuery } from 'src/infra/reactQuery'
import { AuthContext } from 'src/context/AuthenticationContext'
import { useMutation } from '@tanstack/react-query'
import { complaintSearchAccountsColumns } from './gridColumns'
import Last4SSNInput from '../../Accounts/components/Last4SSNInput'
import AccountIDsInput from '../../Accounts/components/AccountIdsInput'
import { DataResponse } from 'src/domain/models/common'
import { useHistory } from 'react-router-dom'
import { getStandardUri } from 'src/utils/common'
import { COMPLAINT_INFO_NEW } from 'src/presentation/routes'
import { IComplaintGetOperations } from 'src/domain/features/get/complaint/complaint'
import { GetComplaintAccountsDto } from 'src/domain/models/complaint'

interface ComplaintSearchAccountsProps {
  businessGetOperations?: IBusinessGetOperations
  complaintGetOperations?: IComplaintGetOperations
}

const ComplaintSearchAccounts: React.FC<ComplaintSearchAccountsProps> = ({
  businessGetOperations,
  complaintGetOperations,
}) => {
  const [accounts, setAccounts] = useState<
    DataResponse<GetComplaintAccountsDto> | undefined
  >()
  const [fetchingAccounts, setIsFetchingAccounts] = useState<boolean>(false)
  const { isCreditor } = useContext(AuthContext)
  const operationType = isCreditor ? 1 : 2
  const methods = useForm()
  const history = useHistory()

  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })

  const creditorId = methods.watch('creditorId')
  const vendorId = methods.watch('vendorId')

  const defaultValues = {
    creditorId: undefined,
    vendorId: undefined,
  }

  const { data: placedBusiness, isLoading: loadingBusiness } = useCustomQuery(
    ['getPlacedBusiness', isCreditor ? creditorId : vendorId],
    async () =>
      businessGetOperations?.getPlacedBusiness(
        [isCreditor ? creditorId : vendorId || ''],
        operationType
      ),
    { cacheTime: 0, enabled: !!creditorId || !!vendorId }
  )

  const mutationGetAccounts = useMutation({
    mutationFn: async (form: any) => {
      setIsFetchingAccounts(true)
      return complaintGetOperations?.getComplaintAccounts(
        gridState,
        form.creditorId,
        form.vendorId,
        form.last4SSN,
        form.accountIDs
      )
    },
    onSuccess: async (response: any) => {
      setIsFetchingAccounts(false)
      setAccounts(response)
    },
    onError: async () => {
      setIsFetchingAccounts(false)
    },
  })

  const handleCreditorsUpdate = (creditor: any) => {
    methods.setValue('creditorId', creditor, { shouldDirty: true })
  }

  const handleVendorsUpdate = (vendor: any) => {
    methods.setValue('vendorId', vendor, { shouldDirty: true })
  }

  const handleFilterClick = (form: any) => {
    mutationGetAccounts.mutate(form)
  }

  const secondDropdownFields = {
    id: `${isCreditor ? 'vendor' : 'placer'}-dropdown`,
    name: isCreditor ? 'vendorId' : 'creditorId',
    placeholder: `Select a ${isCreditor ? 'vendor' : 'placer'}`,
    disabled: isCreditor ? !creditorId : !vendorId,
  }

  const handleCreateButtonClick = (ecaid: string) => {
    history.push(getStandardUri(`${COMPLAINT_INFO_NEW}/${ecaid}`))
  }

  return (
    <Content id="create-complaint-search-accounts">
      <Header title="Create a complaint" />
      <Form
        methods={methods}
        onSubmit={handleFilterClick}
        initialValues={defaultValues}
      >
        <Group alignItems="flex-end" pt={3}>
          {isCreditor ? (
            <Creditors
              businessOperations={businessGetOperations}
              onCreditorsUpdate={handleCreditorsUpdate}
            />
          ) : (
            <Vendors
              businessOperations={businessGetOperations}
              onVendorUpdate={handleVendorsUpdate}
            />
          )}
          <FormDropdown
            id={secondDropdownFields.id}
            name={secondDropdownFields.name}
            maxwidth="300px"
            width="300px"
            options={placedBusiness?.businessList ?? []}
            labelOptionName="businessName"
            valueOptionName="businessId"
            disabled={secondDropdownFields.disabled}
            placeholder={secondDropdownFields.placeholder}
            isLoading={loadingBusiness && (!!creditorId || !!vendorId)}
          />
          <Last4SSNInput />
          <AccountIDsInput />
          <FormSubmitButton text="Filter" />
        </Group>
      </Form>
      <Box pt={4}>
        <DataTable
          isLoading={fetchingAccounts}
          height="100%"
          maxHeight="100%"
          gridColumns={complaintSearchAccountsColumns(handleCreateButtonClick)}
          gridState={gridState}
          data={accounts?.data}
          pageable
          useFilterMenu
          total={accounts?.totalCount}
          onDataStateChange={(e: { dataState: any }) => {
            setGridState(e.dataState)
            mutationGetAccounts.mutate(methods.getValues())
          }}
        />
      </Box>
    </Content>
  )
}

export default ComplaintSearchAccounts
