/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { List, ListItem } from '@material-ui/core'
import {
  DatePicker,
  Dropdown,
  Group,
  ModalDialog,
  Step,
  WarningToast,
} from 'everchain-uilibrary'

import { IBusinessGetOperations } from 'src/domain/features/get/business/business'
import { oneDayPast } from 'src/utils/date'
import { IReportGetOperations } from 'src/domain/features/get/report/report'
import { generateFileBlob } from 'src/utils/file/fileGenerator'
import { useGetBusinessessId } from 'src/utils/user'
import { AuthContext } from 'src/context/AuthenticationContext'

import { useCustomQuery } from 'src/infra/reactQuery'

interface RemittanceReportModalProps {
  open: boolean
  setOpenLoadModal: any
  businessOperations: IBusinessGetOperations
  reportOperations: IReportGetOperations
}

const RemittanceReportModal: React.FC<RemittanceReportModalProps> = ({
  open,
  setOpenLoadModal,
  businessOperations,
  reportOperations,
}: RemittanceReportModalProps) => {
  const [form, setForm] = useState<any>({
    creditorId: '',
    vendorId: '',
    fromDate: oneDayPast(),
    toDate: new Date(),
  })

  const businessId = useGetBusinessessId()
  const { userPermissions, isVendor, isCreditor } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'

  const { data: vendorsList, isLoading: isVendorsLoading } =
    useCustomQuery<any>(
      ['business.getVendors', isVendor, isCreditor],
      async () =>
        isInternal
          ? businessOperations.getVendorsData()
          : businessOperations.getVendorsRelatedData(businessId),
      { cacheTime: 0 }
    )

  const { data: creditorsList, isLoading: isCreditorLoading } =
    useCustomQuery<any>(
      ['business.getCreditors', isVendor, isCreditor],
      async () =>
        isInternal
          ? businessOperations.getCreditorsData()
          : businessOperations.getCreditorsRelatedData(businessId),
      { cacheTime: 0 }
    )

  useEffect(() => {
    setForm({
      ...form,
      vendorId: null,
      creditorId: null,
    })
  }, [isVendor, isCreditor])

  const handleChangeDateFrom = (date: Date) => {
    if (date > form.toDate) {
      setForm({
        ...form,
        fromDate: date,
        toDate: date,
      })
    } else {
      setForm({
        ...form,
        fromDate: date,
      })
    }
  }

  const handleChangeDateTo = (date: Date) => {
    if (date < form.fromDate) {
      setForm({
        ...form,
        fromDate: date,
        toDate: date,
      })
    } else {
      setForm({
        ...form,
        toDate: date,
      })
    }
  }

  const handleGetReportFile = async () => {
    try {
      const responseData = await reportOperations.getRemittanceReport(
        form.fromDate.toISOString(),
        form.toDate.toISOString(),
        form.creditorId,
        form.vendorId
      )

      if (responseData.file !== null) {
        generateFileBlob(responseData)
      } else {
        WarningToast(
          'There is no data that match the selected parameters. Try a different combination.'
        )
      }
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <ModalDialog
        isOpen={open}
        buttonOkText="Download Remittance Report"
        onClose={() => {
          setOpenLoadModal(false)
        }}
        onContinue={() => {
          handleGetReportFile()
        }}
        header={'Remittance Report'}
      >
        <List>
          <ListItem style={{ paddingBottom: '14px' }}>
            <Step
              title="Select the placer"
              stepNumber="1"
              completed={form.creditorId}
            >
              <Dropdown
                width={'350px'}
                options={creditorsList}
                placeholder="Placer"
                onChange={(props: any) => {
                  setForm({
                    ...form,
                    creditorId: props?.id,
                  })
                }}
                isLoading={isCreditorLoading}
                value={form.creditorId}
                valueOptionName="id"
                labelOptionName="name"
              ></Dropdown>
            </Step>
          </ListItem>
          <ListItem style={{ paddingBottom: '14px' }}>
            <Step
              title="Select the vendor"
              stepNumber="2"
              completed={form.vendorId}
            >
              <Dropdown
                width={'350px'}
                options={vendorsList}
                placeholder="Vendor"
                onChange={(props: any) => {
                  setForm({
                    ...form,
                    vendorId: props?.id,
                  })
                }}
                isLoading={isVendorsLoading}
                value={form.vendorId}
                valueOptionName="id"
                labelOptionName="name"
              ></Dropdown>
            </Step>
          </ListItem>
          <ListItem style={{ paddingBottom: '14px' }}>
            <Step
              title="Select the time period"
              stepNumber="3"
              completed={form.fromDate && form.toDate}
            >
              <Group>
                <DatePicker
                  value={form.fromDate}
                  onChange={(date) => {
                    if (date) handleChangeDateFrom(date)
                  }}
                  disablePast={false}
                />
                <DatePicker
                  value={form.toDate}
                  onChange={(date) => {
                    if (date) handleChangeDateTo(date)
                  }}
                  disablePast={false}
                />
              </Group>
            </Step>
          </ListItem>
        </List>
      </ModalDialog>
    </>
  )
}

export default RemittanceReportModal
