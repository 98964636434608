/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-redeclare */
import React, { useState } from 'react'
import {
  Box,
  DataTable,
  Content,
  Header,
  Flex,
  Button,
  DataTableState,
} from 'everchain-uilibrary'
import { IComplaintGetOperations } from 'src/domain/features/get/complaint/complaint'
import { useCustomQuery } from 'src/infra/reactQuery'
import { complaintsColumns } from './gridColumns'
import { useHistory } from 'react-router-dom'
import { getStandardUri } from 'src/utils/common'
import { COMPLAINT_CREATE, COMPLAINT_INFO } from 'src/presentation/routes'
import ComplaintCards from './ComplaintCards'
import { Can } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'

interface ComplaintProps {
  complaintGetOperations?: IComplaintGetOperations
}
const ComplaintList: React.FC<ComplaintProps> = ({
  complaintGetOperations,
}) => {
  const [selectedCard, setSelectedCard] = useState<string>('ActionItems')
  const history = useHistory()

  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })

  const { data: complaintsData, isLoading: loadingComplaintsData } =
    useCustomQuery(
      ['getComplaints', gridState, selectedCard],
      async () =>
        complaintGetOperations?.getComplaints(gridState, selectedCard),
      { cacheTime: 0 }
    )

  const onClickCreateComplaint = () => {
    history.push(getStandardUri(COMPLAINT_CREATE))
  }

  const onRowComplaintClick = (event: any) => {
    history.push(getStandardUri(`${COMPLAINT_INFO}/${event.dataItem.id}`))
  }

  return (
    <Content id="create-complaint-search-accounts">
      <Header title="Comply" />
      <ComplaintCards
        complaintGetOperations={complaintGetOperations}
        isLoading={loadingComplaintsData}
        onCardClick={(card) => setSelectedCard(card.cardKey)}
      />
      <Can do={PermissionCodeAccess.Recover_Complaint_CreateComplaint} on="any">
        <Flex w="100%" justifyContent="flex-end">
          <Button onClick={onClickCreateComplaint}>Create Complaint</Button>
        </Flex>
      </Can>
      <Box pt={4}>
        <DataTable
          style={{ cursor: 'pointer' }}
          isLoading={loadingComplaintsData}
          height="100%"
          maxHeight="100%"
          gridColumns={complaintsColumns}
          gridState={gridState}
          data={complaintsData?.data}
          pageable
          useFilterMenu
          total={complaintsData?.totalCount}
          onDataStateChange={(e: { dataState: any }) => {
            setGridState(e.dataState)
          }}
          onRowClick={onRowComplaintClick}
        />
      </Box>
    </Content>
  )
}

export default ComplaintList
