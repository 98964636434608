/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-redeclare */
import React, { useContext } from 'react'
import {
  Accordion,
  Colors,
  FormDropdown,
  FormInput,
  FormSwitch,
  Grid,
  Icon,
} from 'everchain-uilibrary'
import { AuthContext } from 'src/context/AuthenticationContext'
import { ValueLabelDto } from 'src/domain/models/common'

interface ComplaintResponseAccordionProps {
  accountStatusesData?: ValueLabelDto[]
  isLoading?: boolean
  isLoadingAccountStatusesData?: boolean
}

const ComplaintResponseAccordion: React.FC<ComplaintResponseAccordionProps> = ({
  accountStatusesData,
  isLoading,
  isLoadingAccountStatusesData,
}) => {
  const { isVendor } = useContext(AuthContext)

  return (
    <Accordion
      title="Placer/Vendor Response"
      icon={<Icon name="Info" />}
      defaultOpen
    >
      <Grid
        container
        spacing={4}
        style={{ color: isVendor ? Colors.darkGray : Colors.black }}
      >
        <Grid item xs={12}>
          <FormDropdown
            name="accountStatus"
            placeholder="Account Status"
            options={accountStatusesData ?? []}
            width="100%"
            isLoading={isLoadingAccountStatusesData || isLoading}
            disabled={isVendor}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            name="employeesInvolved"
            placeholder="Employees Involved"
            width="100%"
            isLoading={isLoading}
            disabled={isVendor}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            name="agencyAction"
            placeholder="Agency Action"
            width="100%"
            isLoading={isLoading}
            disabled={isVendor}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            name="agencyResponse"
            placeholder="Agency Response"
            width="100%"
            isLoading={isLoading}
            disabled={isVendor}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            name="agencyPreventionSteps"
            placeholder="Agency Prevention Steps"
            width="100%"
            isLoading={isLoading}
            disabled={isVendor}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSwitch
            name="followUpRequired"
            label="Follow-up Required"
            isLoading={isLoading}
            disabled={isVendor}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSwitch
            name="documentsRequired"
            label="Documents Required"
            isLoading={isLoading}
            disabled={isVendor}
          />
        </Grid>
      </Grid>
    </Accordion>
  )
}

export default ComplaintResponseAccordion
