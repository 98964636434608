import React from 'react'
import { makeBusinessGetData } from '../../../feature/get/Business'
import { makePostPlacementGetData } from '../../../feature/get/PostPlacement'
import { makePostPlacementPostData } from '../../../feature/post/PostPlacement'
import BulkPostPlacementRespond from 'src/presentation/pages/BulkPostPlacement/Respond'

const makeBulkPostPlacementRespond: React.FC = () => {
  return (
    <BulkPostPlacementRespond
      businessOperations={makeBusinessGetData()}
      postPlacementOperations={makePostPlacementGetData()}
      postPlacementPostOperations={makePostPlacementPostData()}
    />
  )
}

export default makeBulkPostPlacementRespond
