import {
  Box,
  Button,
  Content,
  DataTable,
  DataTableState,
  Dropdown,
  ErrorToast,
  Form,
  FormCheckBox,
  FormDropdown,
  FormInput,
  FormSubmitButton,
  Grid,
  Group,
  Icon,
  ModalDialog,
  Paper,
  renderCurrency,
  renderPercent,
  renderYesOrNoBoolean,
  SuccessToast,
  useForm,
  ValidationRulesDictionary,
} from 'everchain-uilibrary'
import React, { useContext, useMemo, useState } from 'react'
import Page from '../../Common/Page'
import { IBusinessGetOperations } from 'src/domain/features/get/business/business'
import { useCustomLazyQuery, useCustomQuery } from 'src/infra/reactQuery'
import { profileCountry } from 'src/utils/user'
import { IAssetTypesGetOperations } from 'src/domain/features/get/assetTypes/assetTypes'
import { getValidation } from 'src/validation/utils'
import { IStrategyGetOperations } from 'src/domain/features/get/strategy/strategy'
import { IStrategyPostOperations } from 'src/domain/features/post/strategy/strategy'
import { AuthContext } from 'src/context/AuthenticationContext'

interface ParameterManagerParams {
  businessOperations: IBusinessGetOperations
  assetTypeOperations: IAssetTypesGetOperations
  strategyGetOperations: IStrategyGetOperations
  strategyPostOperations: IStrategyPostOperations
  validation: ValidationRulesDictionary
}

const ParameterManager: React.FC<ParameterManagerParams> = ({
  businessOperations,
  assetTypeOperations,
  strategyGetOperations,
  strategyPostOperations,
  validation,
}: ParameterManagerParams) => {
  const [creditor, setCreditor] = useState<any>()
  const [vendor, setVendor] = useState<any>()
  const [openMinumumPaymentModal, setOpenMinimumPaymentModal] = useState(false)
  const [formMinumumPayment] = useState<any>({
    makeDefault: false,
    assetTypeId: null,
    minimumPaymentValue: null,
    minimumPaymentPercent: null,
    daysIncrease: null,
    strategyId: null,
  })
  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })
  const [strategyParametersListData, setStrategyParametersListData] =
    useState<any>()
  const [isSaveStrategyParametersLoading, saveStrategyParametersLoading] =
    useState(false)
  const { userPermissions } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'

  const methods = useForm()

  const { data: vendorsList, isFetching: isVendorsLoading } =
    useCustomQuery<any>(
      ['getVendors'],
      async () => businessOperations.getVendorsData(),
      { cacheTime: 0 }
    )

  const { data: creditorsList, isFetching: isCreditorsLoading } =
    useCustomQuery<any>(
      ['getCreditors'],
      async () => businessOperations.getCreditorsData(),
      { cacheTime: 0 }
    )

  const { data: assetTypeList, isFetching: isAssetTypesLoading } =
    useCustomQuery<any>(
      ['getAssetTypes'],
      async () => assetTypeOperations.getAssetTypeData(creditor),
      { enabled: !!creditor, cacheTime: 0 }
    )

  const { data: strategyList, isFetching: isStrategyListLoading } =
    useCustomQuery<any>(
      ['getStrategyByCreditorId'],
      async () => strategyGetOperations.getStrategyByCreditorId(creditor),
      { enabled: !!creditor && !isInternal, cacheTime: 0 }
    )

  const { data: allStrategiesList, isFetching: isAllStrategiesListLoading } =
    useCustomQuery<any>(
      ['getAllStrategies'],
      async () => strategyGetOperations.getAllStrategies(),
      { cacheTime: 0 }
    )

  const strategiesList = useMemo(() => {
    if (isInternal) {
      return allStrategiesList
    }
    return strategyList
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allStrategiesList, strategyList])

  const {
    data: strategyParametersList,
    isFetching: isStrategyParametersFetching,
    isLoading: isStrategyParametersLoading,
    refetch: refetchStrategyParameters,
    isRefetching: isRefetchingStrategyParameters,
  } = useCustomQuery<any>(
    ['getStrategyParameters', gridState],
    async () => {
      const creditorsGuids = creditorsList.map((x: any) => x.id)
      return strategyGetOperations.getStrategiesParameters(
        gridState,
        creditorsGuids
      )
    },
    { cacheTime: 0, enabled: !!creditorsList }
  )

  const { executeQuery: saveStrategyParams } = useCustomLazyQuery<any>(
    ['saveStrategyParameters'],
    async (param: any) => {
      strategyPostOperations
        .saveStrategyParameters(param)
        .then(() => {
          SuccessToast('Strategy Parameters saved successfully')
          onClearModal()
          setOpenMinimumPaymentModal(false)
          refetchStrategyParameters()
          saveStrategyParametersLoading(false)
        })
        .catch(() => {
          ErrorToast('Error saving Strategy Parameters')
        })
    },
    { cacheTime: 0 }
  )

  const handleCreditorUpdate = (selectedCreditor: any) => {
    setCreditor(selectedCreditor)
  }

  const handleVendorUpdate = (selectedVendor: any) => {
    setVendor(selectedVendor)
  }

  const handleMinimumPaymentParamenter = () => {
    setOpenMinimumPaymentModal(true)
  }

  const onMinimumPaymentPercentChange = (props: any) => {
    methods.setValue('minimumPaymentPercent', props.target.value, {
      shouldDirty: true,
      shouldValidate: true,
    })
    methods.clearErrors('minimumPaymentValue')
  }

  const onMinimumPaymentValueChange = (props: any) => {
    methods.setValue('minimumPaymentValue', props.target.value, {
      shouldDirty: true,
      shouldValidate: true,
    })
    methods.clearErrors('minimumPaymentPercent')
  }

  const onMakeDefaultChange = (props: any) => {
    methods.setValue('makeDefault', props, {
      shouldDirty: true,
      shouldValidate: true,
    })

    methods.clearErrors('strategyId')
    methods.setValue('strategyId', null)
  }

  const onClearModal = () => {
    methods.reset()
  }

  const onSubmitStage = (form: any) => {
    saveStrategyParametersLoading(true)
    saveStrategyParams({
      default: form.makeDefault,
      assetTypeId: form.assetTypeId,
      minimumPaymentValue: form.minimumPaymentValue,
      minimumPaymentPercent: form.minimumPaymentPercent,
      daysIncrease: form.daysIncrease,
      strategyId: form.strategyId,
      creditorId: creditor,
      vendorId: vendor,
    })
  }

  const makeDefault = methods.watch('makeDefault')
  const minimumPaymentValue = methods.watch('minimumPaymentValue')
  const minimumPaymentPercent = methods.watch('minimumPaymentPercent')

  const columns = [
    {
      field: 'strategy',
      title: 'Strategy',
      show: true,
      width: 200,
    },
    {
      field: 'assetType',
      title: 'Asset Type',
      show: true,
      width: 200,
    },
    {
      field: 'minimumPaymentValue',
      title: 'Minimum Payment $',
      show: true,
      width: 100,
      render: (props: any) => renderCurrency(props, profileCountry()),
    },
    {
      field: 'minimumPaymentPercent',
      title: 'Minimum Payment %',
      show: true,
      width: 100,
      render: (props: any) => renderPercent(props),
    },
    {
      field: 'daysIncrease',
      title: 'Days Increase',
      show: true,
      width: 100,
    },
    {
      field: 'default',
      title: 'Is Default?',
      show: true,
      width: 100,
      render: (props: any) => renderYesOrNoBoolean(props),
    },
    {
      field: 'creditor',
      title: 'Placer',
      show: true,
      width: 100,
    },
    {
      field: 'vendor',
      title: 'Vendor',
      show: true,
      width: 100,
    },
  ]

  useMemo(() => {
    if (strategyParametersList) {
      if (creditorsList && vendorsList) {
        const data = strategyParametersList?.data?.map((x: any) => {
          return {
            ...x,
            creditor: creditorsList.find(
              (cred: any) => cred.id === x.creditorId
            )?.name,
            vendor: vendorsList.find((vend: any) => vend.id === x.vendorId)
              ?.name,
          }
        })
        setStrategyParametersListData(data)
      }
    }
  }, [strategyParametersList, creditorsList, vendorsList])

  return (
    <Content id="parameter-manager-ct">
      <Page subtitle="Parameter Manager details" title="Parameter Manager">
        <Paper
          style={{ padding: 15, width: 'min-content' }}
          id="strategy-parameters-details"
        >
          <Box display="flex">
            <Group>
              <Grid style={{ display: 'flex', gap: 10 }}>
                <Box>
                  <Dropdown
                    width={'350px'}
                    options={creditorsList}
                    placeholder="Placer"
                    onChange={(props: any) => {
                      handleCreditorUpdate(props?.id)
                    }}
                    isLoading={isCreditorsLoading}
                    value={creditor}
                    valueOptionName="id"
                    labelOptionName="name"
                  ></Dropdown>
                </Box>
                <Box>
                  <Dropdown
                    width={'350px'}
                    options={vendorsList}
                    placeholder="Vendor"
                    onChange={(props: any) => {
                      handleVendorUpdate(props?.id)
                    }}
                    isLoading={isVendorsLoading}
                    value={vendor}
                    valueOptionName="id"
                    labelOptionName="name"
                  ></Dropdown>
                </Box>
                <Box marginTop="10px" style={{ minWidth: 'max-content' }}>
                  <Button
                    useRipple
                    onClick={handleMinimumPaymentParamenter}
                    disabled={!creditor || !vendor}
                  >
                    Minimum Payment Parameters
                  </Button>
                </Box>
              </Grid>
            </Group>
          </Box>
        </Paper>
        <Box mt={15}>
          <DataTable
            sortable={true}
            useFilterMenu={true}
            isFetching={
              isStrategyParametersFetching || isRefetchingStrategyParameters
            }
            isLoading={
              isStrategyParametersLoading || isRefetchingStrategyParameters
            }
            height="100%"
            maxHeight="100%"
            gridColumns={columns || []}
            gridState={gridState}
            data={strategyParametersListData}
            pageable={true}
            total={strategyParametersList?.totalCount}
            onDataStateChange={(e: { dataState: any }) => {
              setGridState(e.dataState)
            }}
          />
        </Box>
      </Page>
      <ModalDialog
        header="Confirmation"
        isOpen={openMinumumPaymentModal}
        onClose={() => {
          setOpenMinimumPaymentModal(false)
        }}
        showActionButtons={false}
        isLoading={
          isStrategyListLoading ||
          isAssetTypesLoading ||
          isAllStrategiesListLoading
        }
        isFetching={isSaveStrategyParametersLoading}
      >
        <Form
          initialValues={formMinumumPayment}
          onSubmit={onSubmitStage}
          methods={methods}
          isLoading={isSaveStrategyParametersLoading}
          isFetching={isSaveStrategyParametersLoading}
        >
          <Grid style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
            <FormDropdown
              validation={getValidation(validation, 'assetTypeId')}
              name="assetTypeId"
              placeholder="Asset Type"
              width="250px"
              options={assetTypeList?.sort((a: any, b: any) =>
                a.name.localeCompare(b.name)
              )}
              valueOptionName="id"
              labelOptionName="name"
              disabled={!creditor || isAssetTypesLoading}
              isFetching={isAssetTypesLoading}
              isLoading={isAssetTypesLoading}
            />
            <FormInput
              id="minimum-payment-money-input"
              validation={getValidation(validation, 'minimumPaymentValue')}
              width="20rem"
              placeholder="Minimum Payment ($)"
              name="minimumPaymentValue"
              isLoading={false}
              disabled={false}
              type="number"
              afterValue="$"
              onChange={(props: any) => {
                onMinimumPaymentValueChange(props)
              }}
              value={minimumPaymentValue}
            />
            <FormInput
              id="minimum-payment-percent-input"
              validation={getValidation(validation, 'minimumPaymentPercent')}
              width="20rem"
              placeholder="Minimum Payment (%)"
              name="minimumPaymentPercent"
              isLoading={false}
              disabled={false}
              type="number"
              afterValue="%"
              onChange={(props: any) => {
                onMinimumPaymentPercentChange(props)
              }}
              value={minimumPaymentPercent}
            />
            <FormInput
              id="days-increase-input"
              validation={getValidation(validation, 'daysIncrease')}
              width="20rem"
              placeholder="Days Increase"
              name="daysIncrease"
              isLoading={false}
              disabled={false}
              type="number"
            />
            <FormCheckBox
              id="Make default"
              name="makeDefault"
              checked={formMinumumPayment.makeDefault}
              label="Make default"
              onChange={(props: any) => {
                onMakeDefaultChange(props)
              }}
            ></FormCheckBox>
            <FormDropdown
              validation={getValidation(validation, 'strategyId')}
              name="strategyId"
              placeholder="Strategy"
              width="250px"
              options={strategiesList?.sort((a: any, b: any) =>
                a.name.localeCompare(b.name)
              )}
              valueOptionName="id"
              labelOptionName="name"
              disabled={makeDefault}
              isFetching={isStrategyListLoading || isAllStrategiesListLoading}
              isLoading={isStrategyListLoading || isAllStrategiesListLoading}
            />
            <Box
              display="flex"
              justifyContent="flex-end"
              mb={2}
              style={{ gap: 10 }}
            >
              <Button
                variant="secondary"
                leftIcon={<Icon name="Block" fontSize="small" />}
                disabled={isSaveStrategyParametersLoading}
                onClick={() => {
                  setOpenMinimumPaymentModal(false)
                }}
              >
                Cancel
              </Button>
              <FormSubmitButton
                disabled={
                  isStrategyListLoading ||
                  isAssetTypesLoading ||
                  isAllStrategiesListLoading
                }
                isLoading={
                  isStrategyListLoading ||
                  isAssetTypesLoading ||
                  isAllStrategiesListLoading
                }
                isFetching={
                  isSaveStrategyParametersLoading ||
                  isRefetchingStrategyParameters ||
                  isStrategyParametersFetching
                }
                text="Save"
              />
            </Box>
          </Grid>
        </Form>
      </ModalDialog>
    </Content>
  )
}
export default ParameterManager
